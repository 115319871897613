import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/BlogTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Video games are a combination of art, technology, and storytelling that transport players into
captivating worlds. In these virtual realms, an NPC’s voice plays a vital role in crafting
compelling narratives and immersing players in the game’s universe. Today, we’re excited to reveal
a technology that will redefine the landscape of Generative Voice AI in video games: XTTS.`}</p>
    <p>{`XTTS, our cutting-edge text-to-speech system, takes a giant leap forward from the
traditional, limited text-to-speech systems, promising to revolutionize the way game
developers create vocal performances. Are you ready to level up your game audio? Then let’s
dive into the exhilarating world of XTTS!`}</p>
    <div align="center">
      <Link to="https://app.coqui.ai/auth/signup" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "137px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "34.306569343065696%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMAGE",
              "title": "IMAGE",
              "src": "/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-introducing-xtts-for-game-studios-try-now-for-free.png",
              "srcSet": ["/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-introducing-xtts-for-game-studios-try-now-for-free.png 137w"],
              "sizes": "(max-width: 137px) 100vw, 137px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </Link>
    </div>
    <h3 {...{
      "id": "raising-the-bar-with-xtts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#raising-the-bar-with-xtts",
        "aria-label": "raising the bar with xtts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Raising the Bar with XTTS`}</h3>
    <p>{`XTTS, our state-of-the-art technology, builds upon the latest advancements in Generative Voice AI.
It’s a technology built for game developers with a singular aim: to inject a dose of realism and
expressivity that will make NPCs come alive!`}</p>
    <p>{`Unlike its predecessors, XTTS takes a massive leap forward, producing stunningly expressive outputs
and superior Generative Voice AI for game characters. This is game audio evolution - allowing game
characters to sound not just realistic, but inspire you, scare you, and make you feel something.`}</p>
    <p>{`With XTTS, you’ll have access to enhanced Coqui Studio features, including prompt-to-voice and voice
cloning. XTTS is not just another tech upgrade; it’s a powerful tool that expands the limits of your
creativity and empowers you to build captivating audio narratives.`}</p>
    <h3 {...{
      "id": "xtts-your-key-to-creating-vivid-immersive-gaming-experiences",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#xtts-your-key-to-creating-vivid-immersive-gaming-experiences",
        "aria-label": "xtts your key to creating vivid immersive gaming experiences permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`XTTS: Your Key to Creating Vivid, Immersive Gaming Experiences`}</h3>
    <p>{`Picture this: Your game character walks into a dimly lit room. The suspense is building, and the
music intensifies. Now, imagine your character speaking - not with a robotic, monotonous tone,
but a voice that’s filled with emotion, character, and depth. That’s the power of XTTS.`}</p>
    <p>{`Using XTTS, you can generate game character voices that convey the nuances of emotion and subtleties
of human speech, delivering immersive experiences that will have your players on the edge of their
seats. Quickly create dialog and customize emotions for all characters with our intuitive
text-to-speech Generative Voice AI platform.`}</p>
    <h3 {...{
      "id": "stay-tuned-were-just-getting-started",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#stay-tuned-were-just-getting-started",
        "aria-label": "stay tuned were just getting started permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Stay Tuned: We’re Just Getting Started!`}</h3>
    <p>{`This is only the beginning! We’re committed to making XTTS even more robust and versatile. Over the
coming months, we’ll be adding more languages, speeding up the technology, and enhancing its
performance even further. So keep your eyes peeled for more exciting updates!`}</p>
    <h3 {...{
      "id": "a-new-era-of-game-audio-starts-now",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#a-new-era-of-game-audio-starts-now",
        "aria-label": "a new era of game audio starts now permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`A New Era of Game Audio Starts Now`}</h3>
    <p>{`XTTS isn’t just a new product; it’s a breakthrough in game development. By bringing human-like
expressivity and unparalleled realism to your characters, XTTS pushes the boundaries of what’s
possible with game audio. It’s more than human - it’s the future of vocal performance in gaming.`}</p>
    <p>{`So why wait? It’s time to experience the revolution firsthand. Try out XTTS through our API and
let your characters speak for themselves! We can’t wait to hear your thoughts and see the
amazing worlds you’ll create with XTTS. So go ahead, take the leap, and let’s redefine gaming
together!`}</p>
    <div align="center">
      <Link to="https://app.coqui.ai/auth/signup" mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "137px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "34.306569343065696%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMAGE",
              "title": "IMAGE",
              "src": "/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-introducing-xtts-for-game-studios-try-now-for-free.png",
              "srcSet": ["/static/0addc085d063dac762fd9b75a388f199/3f2c9/blog-introducing-xtts-for-game-studios-try-now-for-free.png 137w"],
              "sizes": "(max-width: 137px) 100vw, 137px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span></p>
      </Link>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      